package ui.consumerInformation

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.material.CircularProgressIndicator
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import kotlinx.coroutines.flow.StateFlow
import model.ConsumerInformationV2
import model.MerchantFullInformationV2
import ui.scrollbar.LazyColumnScrollbar

@Composable
fun ConsumerInformationScreen(
    onMerchantClicked: (MerchantFullInformationV2) -> Unit,
    fetchConsumerInformation: StateFlow<ConsumerInformationV2?>,
    merchantCardView: @Composable (MerchantFullInformationV2, (MerchantFullInformationV2) -> Unit) -> Unit,
    shouldShowScrollbar: Boolean,
) {
    val _consumerInformation by fetchConsumerInformation.collectAsState()
    val consumerInformation = _consumerInformation
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .fillMaxHeight(),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        val lazyListState = rememberLazyListState()

        if (consumerInformation != null) {
            LazyColumnScrollbar(
                listState = lazyListState,
                padding = 4.dp,
                alwaysShowScrollBar = shouldShowScrollbar,
            ) {
                LazyColumn(
                    state = lazyListState,
                    modifier = Modifier
                ) {
                    item {
                        Text(
                            modifier = Modifier.fillMaxWidth(),
                            text = "Loyalty Member",
                            fontSize = 25.sp,
                            fontWeight = FontWeight.Bold,
                            textAlign = TextAlign.Center,
                        )
                        Spacer(Modifier.height(8.dp))
                        ConsumerInformationDisplay(consumerInformation)
                        ConsumerCardDisplay(consumerInformation.cards.filterNotNull(), shouldShowScrollbar)
                        Text(
                            "My Stores",
                            fontWeight = FontWeight.ExtraBold,
                            modifier = Modifier.align(Alignment.Start).padding(start = 16.dp, top = 16.dp, bottom = 16.dp),
                            fontSize = 20.sp
                        )
                    }
                    ConsumerStoreInformationDisplay(
                        consumerInformation.merchants.filterNotNull(),
                        merchantCardView,
                        onMerchantClicked
                    )
                    item {
                        ReferProgramCardView()
                    }
                }
            }
        } else {
            CircularProgressIndicator()
        }
    }
}