// No need to match package structure, to force compile time switch of dependencies
class PreProdFlavor : Flavor {
    override val clientName: String = "Linx-PreProd"
    override val clientUrl: String = "linx-preprod.enrollandpay.com"
    override val serverHttps: Boolean = true
    override val serverUrl: String = "linx-appserver-preprod.azurewebsites.net"
    override val serverPort: Int? = null
    override val databaseConnectionString: String =
        "jdbc:sqlserver://enrollandpay.database.windows.net:1433;database=EnrollAndPayV2-PreProduction;encrypt=true;trustServerCertificate=false;hostNameInCertificate=*.database.windows.net;loginTimeout=30;"
    override val serverBusConnectionString: String = "Endpoint=sb://enrollandpaypreproduction.servicebus.windows.net/;SharedAccessKeyName=RootManageSharedAccessKey;SharedAccessKey=elUF1DbKIIKNXUSPjMnhUnvURl+G3W9DaktYimSJeeI="
    override val enpTerminalServerHost: String = "https://enpterminalapi-preproduction.azurewebsites.net/"
    override val token: String = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnZpcm9ubWVudCI6InByZXByb2QiLCJuYW1lIjoiZW5yb2xsYW5kcGF5In0.NPCzaC8s0Tly9FGtI-MkIvgl3oN2GxC-G4KcfUnn53g"
}